import React, {Fragment} from 'react';
import {
    FormTab,
    TabbedForm,
    SelectInput,
    TextInput,
    TextField,
    NumberInput,
    LongTextInput,
    BooleanInput,
    DateInput,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteArrayInput,
    translate as trans,
    ReferenceInput,
    SimpleFormIterator,
    ArrayInput, AutocompleteInput
} from 'react-admin';
import { Field, FieldArray } from 'redux-form';
import {expectMinTTLOf, required} from '../validate';
import EditToolbar from '../EditToolbar';
import ContentTypeInput from '../../components/ContentTypeInput';
import FeedTemplateInput from '../../components/FeedTemplateInput';
import validateFeed from './validateFeed';
import Form from './Form';
import { FormDataConsumer } from 'react-admin';
import {checkUserIsSuperAdmin} from "../../authProvider";
import { withStyles } from '@material-ui/core/styles';
import TagsSelect from "../../components/DynamicSelect";
import CommonDynamicFieldsComponents from "../../components/CommonDynamicFieldsComponents";
import AceEditorInput from "../../components/AceEditorInput";
import TextareaAutoComplete from "../../components/TextareaAutoComplete";
import ChangelogField from "../../components/ChangelogField";
import AsideFilters from "../../components/AsideFilters";
import Grid from "../../components/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import orderFields from './fields/orderFields';
import metricsRegionsFields from './fields/metricsRegionsFields';
import metricsTypesFields from './fields/metricsTypesFields';
import Debug from "../../components/Debug";
import CustomBooleanInput from "../../components/CustomBooleanInput";

const styles = () => ({
    hide: {
        display: 'none',
    },
    optionsPaper: {
        padding: '8px 24px',
        width: '100%',
        margin: '20px 0',
        backgroundColor: '#F6F6F6'
    }
});

// TODO on unticking metrics checkbox metrics are nullified
const EditForm = ({classes, translate, ...props }) => {

    return <Grid columns="2" style={{gridTemplateColumns:"1fr 20%"}} >
    <TabbedForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
        validate={validateFeed}
        {...props}
    >
        <FormTab label="summary">
            <Form>
                <TextInput source="q"/>
                <TextField source="guid"/>
                {checkUserIsSuperAdmin(localStorage.getItem('role').split(",")) && <TextInput source="updated_guid" label={"New Guid (id)"}/>}

                <TextInput source="headline" validate={required()} />
                <SelectInput
                    source="feed_type"
                    choices={window.feed_type}
                    validate={required()}
                    defaultValue="rss"
                />
                <SelectInput
                    source="feed_document_type"
                    choices={[
                        { id: 'article', name: 'article' },
                        { id: 'curatedlist', name: 'curated list' },
                        { id: 'productcampaign', name: 'deals' },
                        { id: 'videos', name: 'videos' },
                        { id: 'shopping', name: 'shopping' },
                        { id: 'forums', name: 'forums' },
                    ]}
                    defaultValue="article"
                    validate={required()}
                    //reload = {FeedTemplateInput.forceUpdate()}
                />
                <TextInput source="link" />
                <LongTextInput source="dek" />


                <ReferenceInput source="custom_template_guid" reference="customtemplates" allowEmpty><SelectInput optionText="headline"/></ReferenceInput>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData && (['custom', 'sitemap'].indexOf(formData.feed_type) !== -1) &&
                        <Fragment>
                            <FeedTemplateInput source="feed_template" data={formData}/>
                            <Field labl="Item template" name="item_template" source="item_template" component={AceEditorInput} data={formData}/>
                            <FieldArray mylabel="Custom Placeholders" name="custom_placeholders" component={CommonDynamicFieldsComponents} feedDocumentType={formData.feed_document_type} data={formData}/>
                        </Fragment>
                    }
                </FormDataConsumer>

                <TextInput source="exclude_terms"  helperText="Use this filter to exclude when article name contains specified terms"/>
                <TextInput source="item_separator" />
                <TextInput source="item_formative" />
                <TextInput source="item_link" />
                <TextInput source="item_author" />
                <TextInput helperText="Use this filter the partner to who you want to say the generic content" source="content_syndication_control_tag" />
                <TextInput helperText="Use this filter to select which site generic content comes from" source="content_syndication_tag_handle" />
                <TextInput classes={{ root: classes.hide }} source="content_syndication_domain" />
                <TextInput source="tracking" />
                <TextInput source="custom_query" />
                <TextInput source="custom_filter_query" />
                <TextInput source="channel_link" />
                <TextInput source="channel_title" />
                <TextInput source="extra_content_url" />
                <Field label="Custom Filter" name="custom_filter" source="custom_filter" suggests={window.customFilterPlacehodersChoices} component={TextareaAutoComplete} />
                <FieldArray mylabel="Multi Custom Query" name="multi_custom_query" helper={"Add custom query rule for each site selected"} component={CommonDynamicFieldsComponents} />
                <FieldArray mylabel="Multi Custom Filter Query" name="multi_custom_filter_query" helper={"Add custom filter query rule for each site selected"} component={CommonDynamicFieldsComponents} />
                <NumberInput
                    source="ttl"
                    validate={expectMinTTLOf()}
                    transVariables={{time: localStorage.getItem('role').split(",").indexOf('SuperAdmin') !== -1 ? 60 : 600}}
                />

                <Field name="content_type" component={ContentTypeInput} />

                <FormDataConsumer>
                    {({ formData }) =>
                        (formData && ['sitemap', 'sitemap-news'].indexOf(formData.feed_type) !== -1) &&
                        <Fragment>
                            <Field
                                label="feeds.sitemap_index_threshold_helper"
                                name="sitemap_index_threshold" source="sitemap_index_threshold"
                                component={NumberInput}
                                fullWidth={true}
                                defaultValue={500}
                            />
                        </Fragment>
                    }
                </FormDataConsumer>

                <ReferenceArrayInput
                    source="companies"
                    reference="corporations"
                >
                    <SelectArrayInput
                        optionText="headline"
                    />
                </ReferenceArrayInput>
                <FormDataConsumer>
                    {({ formData }) =>
                        <Form resource="feeds">
                            {formData && formData.feed_document_type === 'article' && renderFilters(props)}
                            {formData && formData.feed_document_type === 'videos' && renderVideoFilters()}
                            {formData && formData.feed_document_type === 'shopping' && renderShoppingFilters()}
                            {formData && formData.feed_document_type === 'forums' && renderForumFilters()}
                            {formData && formData.feed_document_type === 'curatedlist' && renderCuratedFilters()}
                        </Form>
                    }
                </FormDataConsumer>

                <Paper 
                        classes={{root: classes.optionsPaper}} 
                        source={props.record.metric_region_field && 
                            props.record.metric_region_field !== '' ? "metric_region_field": "articles_metrics_data"}
                    >
                    <Typography variant="headline" gutterBottom>
                        Articles Metrics Data
                        <Typography variant="caption" gutterBottom>
                            Get the top entries in regard of metrics data
                        </Typography>
                    </Typography>
                    <Grid columns="2">
                        <SelectInput
                            label="Select Metrics Region (default => all)"
                            source="metric_region_field"
                            choices={metricsRegionsFields}
                            allowEmpty
                        />
                        <SelectInput
                            label="Select Metrics Type (default => popularity)"
                            source="metric_type_field"
                            choices={metricsTypesFields}
                            allowEmpty
                        />
                        <NumberInput
                            label="Select Metrics Rank Start (default => 1)"
                            source="metric_rank_start_field"
                            allowEmpty
                        />
                        <NumberInput
                            label="Select Metrics Rank End (default => 100)"
                            source="metric_rank_end_field"
                            allowEmpty
                        />                       
                    </Grid>
                    <Typography variant="subheading" style={{marginTop:"1rem"}} gutterBottom>
                        Select latest metrics time span
                    </Typography>
                    <Grid columns="2">
                        <NumberInput
                            label="Published from now to x hours/days/months... (default => 30)"
                            source="metric_date_value_field"
                            allowEmpty
                        />
                        <SelectInput
                            label="Select Time Unit Field (default => days)"
                            source="metric_time_unit_field"
                            choices={[
                                { id: 'HOURS', name: 'hours' },
                                { id: 'DAYS', name: 'days' },
                                { id: 'MONTHS', name: 'months' },
                                { id: 'YEARS', name: 'years' }
                            ]}
                            allowEmpty
                        />
                    </Grid>
                </Paper>

            </Form>

            <Paper classes={{root: classes.optionsPaper}}>
                <Typography variant="headline" gutterBottom>
                    Options
                </Typography>
                <Grid columns="5">
                    <CustomBooleanInput source="full_content" tooltipMessage="full_content_helper"/>
                    <CustomBooleanInput source="camel_case" tooltipMessage="camel_case_helper"/>
                    <CustomBooleanInput source="custom_template_switcher" tooltipMessage="custom_template_switcher_helper"/>
                    <CustomBooleanInput source="disable_trailing_slash" tooltipMessage="disable_trailing_slash_helper"/>
                    <CustomBooleanInput source="more_thumbnails" tooltipMessage="more_thumbnails_helper"/>
                    {checkUserIsSuperAdmin(localStorage.getItem('role').split(",")) &&
                        <CustomBooleanInput source="ttl_to_zero" tooltipMessage="ttl_tooltip_helper"/>
                    }
                    <CustomBooleanInput source="affiliate_disclaimer" tooltipMessage="affiliate_disclaimer_helper"/>
                    <CustomBooleanInput source="strip_tags" tooltipMessage="strip_tags_helper"/>
                    <CustomBooleanInput source="exclude_embedded_jwp" tooltipMessage="exclude_embedded_jwp_helper"/>
                    <CustomBooleanInput source="no_hyperlinks" tooltipMessage="no_hyperlinks_helper"/>
                    <CustomBooleanInput source="use_listing_title" tooltipMessage="use_listing_title_helper"/>
                    <CustomBooleanInput source="affiliation" tooltipMessage="affiliation"/>
                    <CustomBooleanInput source="affiliation_real_time" tooltipMessage="affiliation_real_time"/>
                    <CustomBooleanInput source="include_embargoed_articles" tooltipMessage="include_embargoed_articles_helper"/>
                    <CustomBooleanInput source="disable_site_config" tooltipMessage="disable_site_config"/>
                </Grid>
                <Grid columns="2">
                    <SelectInput
                        source="order_field"
                        allowEmpty
                        choices={orderFields}
                    />
                    <SelectInput
                        source="order_dir"
                        allowEmpty
                        choices={[
                            { id: 'desc', name: 'DESC' },
                            { id: 'asc', name: 'ASC' }
                        ]}
                    />
                </Grid>
                <Grid columns="2">
                    <NumberInput source="start" />
                    <NumberInput source="nb_results" />
                </Grid>
                <Grid columns="4">
                    <SelectInput
                        source="relative_date_from"
                        allowEmpty
                        choices={window.relative_date_choices}
                    />
                    <SelectInput
                        source="relative_time_from"
                        allowEmpty
                        choices={window.relative_time_choices}
                    />
                    <SelectInput
                        source="relative_date"
                        allowEmpty
                        choices={window.relative_date_choices}
                    />
                    <SelectInput
                        source="relative_time"
                        allowEmpty
                        choices={window.relative_time_choices}
                    />
                </Grid>
                <Grid columns="2">
                    <SelectInput
                        source="images_size"
                        allowEmpty
                        choices={[
                            { id: '1280', name: '1280 px' },
                            { id: '845', name: '845 px' },
                        ]}
                    />
                </Grid>
                {props.record.feed_document_type === 'forums' &&
                    <Grid columns="2">
                        <FieldArray
                            component={TagsSelect}
                            label="Exclude nodes"
                            name="exclude_nodes"
                            record={props.record}
                            commonNodes={window.common_nodes}
                        >

                        </FieldArray>
                    </Grid>
                }
                <Grid columns="2">
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.feed_document_type === 'article' &&
                            <Form resource="feeds">
                                <DateInput source="created_date_from" />
                                <DateInput source="created_date_to" />
                                <DateInput source="modified_date_from" />
                                <DateInput source="modified_date_to" />
                                <TextInput source="recent_x_hour" />
                                <DateInput source="publish_date_from" />
                                <DateInput source="publish_date_to" />
                                <SelectArrayInput
                                    source="excludeArticleBlockTypes"
                                    allowEmpty
                                    choices = {[
                                        { id: 'boxout', name: 'Boxout' },
                                        { id: 'image', name: 'Image' },
                                        { id: 'buying-guide', name: 'Buying Guide' },
                                        { id: 'text', name: 'Text' },
                                        { id: 'gallery', name: 'Gallery' },
                                        { id: 'heading', name: 'Heading' },
                                        { id: 'list', name: 'List' },
                                    ]}
                                />


                            </Form>
                        }
                    </FormDataConsumer>
                </Grid>

            </Paper>
            <LongTextInput helperText="Use this text area to indicate the context of use of this feed" source="comment" />
        </FormTab>
        {props.permissions && props.permissions.checkUserCanRead('feeds_log') &&
            <FormTab label="Changelogs" path="changelogs">
                <ChangelogField guid={props.record.guid} />
            </FormTab>
        }
        {props.permissions && props.permissions.checkUserIsSuperAdmin() && ['article', 'curatedlist'].indexOf(props.record.feed_document_type) >= 0 &&
            <FormTab label="debug"  path="debug">
                <Debug guid={props.record.guid} queries={props.record.debug_queries}/>
            </FormTab>
        }
    </TabbedForm>
    <AsideFilters></AsideFilters>
    </Grid>
};

function renderShoppingFilters() {
    return [
        <SelectInput
            source="code"
            choices={[
                {id: 'TRD', name: 'TechRadar'},
                {id: 'GRD', name: 'GamesRadar'},
                {id: 'T3', name: 'T3'},
                {id: 'PCG', name: 'PCGamer'},
                {id: 'CQB', name: 'Creativebloq'},
                {id: 'DigitalCameraWorld', name: 'DigitalCameraWorld'},
                {id: 'MRD', name: 'MusicRadar'},
                {id: 'THERADAR', name: 'TheRadar'},
                {id: 'DCW', name: 'DigitalCameraWorld'},
                {id: 'REALHOMES', name: 'REALHOMES'},
                {id: 'GIZ', name: 'Gizmodo'},
                {id: 'KOT', name: 'Kotaku'},
                {id: 'LFH', name: 'LifeHacker'},
                {id: 'HOMEBUILDING', name: 'HOMEBUILDING'},
                {id: 'THEBESTVPNFORME', name: 'THEBESTVPNFORME'},
                {id: 'LOUDERSOUND', name: 'LOUDERSOUND'},
                {id: 'GUITARWORLD', name: 'GUITARWORLD'},
                {id: 'WHATHIFI', name: 'WHATHIFI'},
                {id: 'PRACTICALCARAVAN', name: 'PRACTICALCARAVAN'},
                {id: 'PRACTICALMOTORHOME', name: 'PRACTICALMOTORHOME'},
                {id: 'TOMSHARDWARE', name: 'TOMSHARDWAREE'},
                {id: 'TOMSHARDWAREUK', name: 'TOMSHARDWAREUK'},
                {id: 'TOMSGUIDE', name: 'TOMSGUIDE'},
                {id: 'TOPTENREVIEWS', name: 'TOPTENREVIEWS'},
                {id: 'ANANDTECH', name: 'ANANDTECH'},
                {id: 'LAPTOPMAG', name: 'LAPTOPMAG'},
                {id: 'AVNETWORK', name: 'AVNETWORK'},
                {id: 'TECHLEARNING', name: 'TECHLEARNING'},
                {id: 'SPACE', name: 'SPACE'},
                {id: 'LIVESCIENCE', name: 'LIVESCIENCE'},
                {id: 'CYCLINGNEWS', name: 'CYCLINGNEWS'},
            ]}
        />,
        <FieldArray mylabel="Models" name="models" component={CommonDynamicFieldsComponents} />,
        <TextInput key="tags" source="tags" />,
        <ReferenceArrayInput
            source="site"
            reference="publishers"
            validate={required()}
        >
            <AutocompleteArrayInput optionText="headline" fullWidth />
        </ReferenceArrayInput>,
    ];
}

function renderVideoFilters() {
    return [
        <SelectInput
            source="account"
            allowEmpty
            choices={window.jwpAccounts}
        />,
        <TextInput key="video_id" source="video_id" />,
        <TextInput key="playlist_id" source="playlist_id" />,
        <TextInput key="api_key" source="api_key" />,
        <TextInput key="api_secret_key" source="api_secret_key" />,
        <TextInput key="update" source="update" />,
        <TextInput key="tags" source="tags" />,
        <TextInput key="exclude_tags" source="exclude_tags" />,
        <ReferenceArrayInput
            //key="site"
            source="site"
            reference="publishers"
            perPage={100}
            validate={required()}
        >
            <AutocompleteArrayInput optionText="headline" fullWidth />
        </ReferenceArrayInput>,
        <FieldArray mylabel="Api Parameters" name="api_params" helper={"https://developer.jwplayer.com/jwplayer/reference#get_videos-list"} component={CommonDynamicFieldsComponents} />,
        <ArrayInput source="multi_sites_videos">
            <SimpleFormIterator inline fullWidth>
                <ReferenceInput
                    source="site_name"
                    reference="publishers"
                    perPage={100}
                    label={''}
                >
                    <AutocompleteInput optionText="headline" optionValue="source" suggestionLimit="3" style={{width: '100%'}}/>
                </ReferenceInput>
            </SimpleFormIterator>
        </ArrayInput>
    ];
}

function renderCuratedFilters() {
    return [
        <TextInput key="curatedListId" source="curatedListId" validate={required()}/>,
        <ReferenceArrayInput
            source="site"
            reference="publishers"
            perPage={100}
            validate={required()}
        >
            <AutocompleteArrayInput optionText="headline" fullWidth />
        </ReferenceArrayInput>,
        <SelectArrayInput source="articleTerritory" choices={window.articlesTerritoryChoices} defaultValue={['GB']} validate={required()}/>,
        <SelectInput source="articleLanguage" choices={window.articlesLangChoices} defaultValue="en" validate={required()}/>,
        <FieldArray mylabel="Curated List IDs" name="curatedListIds" helper={"To pull curated list from multiple sites"} component={CommonDynamicFieldsComponents} />
    ];
}

function renderForumFilters() {
    let xfSiteChoices = window.sitesXfChoices ? window.sitesXfChoices.map(x => x.id).join('|') : '';

    return [
        <TextInput key="thread_id" source="thread_id" />,
        <TextInput key="tags" source="tags" />,
        <ReferenceArrayInput
            key="site"
            source="site"
            reference="publishers"
            perPage={100}
            filter={{ guid: xfSiteChoices}}
            validate={required()}
        >
            <AutocompleteArrayInput optionText="headline" fullWidth />
        </ReferenceArrayInput>
    ];
}

function renderFilters(props) {

    if(typeof props === 'undefined') {
        props = '';
    }
    return window.solr_indexed_fields
        .filter(source => !['id'].includes(source))
        .map(source => {

            switch(source) {
                case 'articleBody':
                case 'articleMetaDescription':
                case 'articleProductText':
                case 'text':
                    return <LongTextInput key={source} source={source} />;

                case 'productRating':
                    return <NumberInput key={source} source="productRating" />;

                case 'dateTo':
                    return <DateInput key={source} source="dateTo" />;

                case 'dateFrom':
                    return <DateInput key={source} source="dateFrom" />;

                case 'publishedState':
                    return <BooleanInput key={source} source="publishedState" defaultValue={true} />;

                case 'articleTag':
                    return (
                        <FieldArray
                            name="articleTag"
                            label="Article Tags"
                            component={TagsSelect}
                            key={source}
                            source="articleTag"
                            record={props.record}
                        >

                        </FieldArray>
                    );

                case 'site':
                    return (
                        <ReferenceArrayInput
                            key={source}
                            source="site"
                            label="Site"
                            reference="publishers"
                            perPage={100}
                            validate={required()}
                        >
                            <AutocompleteArrayInput optionText="headline" fullWidth />
                        </ReferenceArrayInput>
                    );

                case 'operator':
                    return (
                        <SelectInput
                            key={source}
                            source="operator"
                            label="Operator"
                            resettable
                            allowEmpty
                            choices={
                                [
                                    {id:"AND", name:"AND"},
                                    {id:"OR", name:"OR"},
                                ]}
                        />
                    );

                case 'articleLanguage':
                    return (<SelectInput key={source} source="articleLanguage" choices={window.articlesLangChoices} defaultValue="en" validate={required()}/>);

                case 'articleTerritory':
                    return (<SelectArrayInput key={source} source="articleTerritory" choices={window.articlesTerritoryChoices} defaultValue={['GB']} validate={required()}/>);

                case 'excludearticleControlTag':
                    return <TextInput key={source} source="excludearticleControlTag" initialValue="amazon-feed-clone" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleProduct':
                    return <TextInput key={source} source="excludeArticleProduct" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleProductHandle':
                    return <TextInput key={source} source="excludeArticleProductHandle" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleType':
                    return <TextInput key={source} source="excludeArticleType" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleTemplate':
                    return <TextInput key={source} source="excludeArticleTemplate" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleTagType':
                    return <TextInput key={source} source="excludeArticleTagType" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleTag':
                    return <TextInput key={source} source="excludeArticleTag" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleVerticalHandle':
                    return <TextInput key={source} source="excludeArticleVerticalHandle" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleCategoryHandle':
                    return <TextInput key={source} source="excludeArticleCategoryHandle" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleVertical':
                    return <TextInput key={source} source="excludeArticleVertical" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleAuthorHandle':
                    return <TextInput key={source} source="excludeArticleAuthorHandle" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeArticleMediaRights':
                    return <TextInput key={source} source="excludeArticleMediaRights" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                case 'excludeLegacyId':
                    return <TextInput key={source} source="excludeLegacyId" helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;

                default:
                    return <TextInput key={source} source={source} helperText="Ability to add multiple values. Comma separated means ORs between the values and '&&' means AND between values"/>;
            }
        });

}

export default withStyles(styles)(trans(EditForm));

export { renderFilters }
