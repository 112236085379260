import React from 'react';
import {
    DateField,
    SelectField,
    TextField,
    SimpleShowLayout,
    RichTextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    NumberField,
    BooleanField,
    Labeled,
} from 'react-admin';
import Show from '../Show';
import { reduxForm } from 'redux-form'
import orderFields from './fields/orderFields';

const FeedsShow = props => (
    <Show title="Pagelayout show" {...props}>
        <SimpleShowLayout>
            <TextField
                label="feeds.guid"
                source="guid"
            />
            <TextField
                label="feeds.headline"
                source="headline"
            />
            <TextField
                label="Type"
                source="feed_document_type"
            />
            <SelectField
                label="feeds.feed_type"
                source="feed_type"
                choices={window.feed_type}
            />
            <SelectField
                label="feeds.feed_document_type"
                source="feed_document_type"
                choices={[
                    { id: 'article', name: 'article' },
                    { id: 'productcampaign', name: 'deals' }
                ]}
            />
            <SelectField
                label="feeds.articleLanguage"
                source="articleLanguage"
                choices={window.articlesLangChoices}
            />
            <Labeled label="feeds.articleTerritory">
                <ChipField
                    label="feeds.articleTerritory"
                    source="articleTerritory"
                    choices={window.articlesTerritoryChoices}
                />
            </Labeled>
            <ReferenceArrayField
                label="feeds.internaltags"
                reference="internaltags"
                source="internaltags"
            >
                <SingleFieldList>
                    <ChipField source="headline" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField
                label="feeds.articleControlTag"
                source="articleControlTag"
            />
            <ReferenceArrayField
                label="feeds.site"
                reference="publishers"
                source="site"
            >
                <SingleFieldList>
                    <ChipField source="headline" />
                </SingleFieldList>
            </ReferenceArrayField>
            <NumberField
                label="feeds.nb_results"
                source="nb_results"
            />

            <NumberField label="feeds.sitemap_index_threshold_helper" source="sitemap_index_threshold"/>

            <DateField
                source="created_date_from"
                label="feeds.created_date_from"
            />
            <DateField
                source="created_date_to"
                label="feeds.created_date_to"
            />
            <DateField
                source="modified_date_from"
                label="feeds.modified_date_from"
            />
            <DateField
                source="modified_date_to"
                label="feeds.modified_date_to"
            />
            <TextField
                label="feeds.recent_x_hour"
                source="recent_x_hour"
            />
            <DateField
                source="publish_date_from"
                label="feeds.publish_date_from"
            />
            <DateField
                source="publish_date_to"
                label="feeds.publish_date_to"
            />
            <SelectField
                source="relative_date"
                label="feeds.relative_date"
                choices={window.relative_date_choices}
            />
            <SelectField
                source="relative_date_from"
                label="feeds.relative_date_from"
                choices={window.relative_date_choices}
            />
            <SelectField
                source="order_field"
                label="feeds.order_field"
                choices={orderFields}
            />
            <NumberField
                label="feeds.start"
                source="start"
            />
            <TextField
                label="feeds.articleCategory"
                source="articleCategory"
            />
            <ReferenceArrayField
                label="feeds.exclude_internaltags"
                reference="internaltags"
                source="exclude_internaltags"
            >
                <SingleFieldList>
                    <ChipField source="headline" />
                </SingleFieldList>
            </ReferenceArrayField>
            <RichTextField
                label="feeds.feed_template"
                source="feed_template"
            />
            <RichTextField
                label="feeds.item_template"
                source="item_template"
            />
            <BooleanField
                label="feeds.full_content"
                source="full_content"
            />
            <TextField
                label="feeds.exclude_terms"
                source="exclude_terms"
            />
            <TextField
                label="feeds.item_separator"
                source="item_separator"
            />
            <TextField
                label="feeds.item_formative"
                source="item_formative"
            />
            <TextField
                label="feeds.content_type"
                source="content_type"
            />
            <TextField
                label="feeds.articleAuthor"
                source="articleAuthor"
            />
            <TextField
                label="feeds.articleAuthorId"
                source="articleAuthorId"
            />
            <RichTextField
                label="feeds.articleBody"
                source="articleBody"
            />
            <TextField
                label="feeds.articleBrand"
                source="articleBrand"
            />
            <TextField
                label="feeds.articleBrandId"
                source="articleBrandId"
            />
            <TextField
                label="feeds.articleCategory"
                source="articleCategory"
            />
            <TextField
                label="feeds.articleCompany"
                source="articleCompany"
            />
            <TextField
                label="feeds.articleTemplate"
                source="articleTemplate"
            />
            <TextField
                label="feeds.articleProduct"
                source="articleProduct"
            />
            <TextField
                label="feeds.documentUrl"
                source="documentUrl"
            />
            <TextField
                label="feeds.articleVertical"
                source="articleVertical"
            />
            <TextField
                label="feeds.articleEventHandle"
                source="articleEventHandle"
            />
            <TextField
                label="feeds.articleDisciplineName"
                source="articleDisciplineName"
            />
            <TextField
                label="feeds.articleDisciplineHandle"
                source="articleDisciplineHandle"
            />
            <TextField
                label="feeds.articlePersonUrl"
                source="articlePersonUrl"
            />
            <TextField
                label="feeds.articlePersonGroupUrl"
                source="articlePersonGroupUrl"
            />
            <TextField
                label="feeds.articlePersonGroupName"
                source="articlePersonGroupName"
            />
            <TextField
                label="feeds.articleEventGroupName"
                source="articleEventGroupName"
            />
            <TextField
                label="feeds.articleLiveCoverageStart"
                source="articleLiveCoverageStart"
            />
            <TextField
                label="feeds.articleLiveCoverageEnd"
                source="articleLiveCoverageEnd"
            />
            <TextField
                label="feeds.articleVoucherRetailerCategory"
                source="articleVoucherRetailerCategory"
            />
            <TextField
                label="feeds.eventGroupName"
                source="eventGroupName"
            />
            <TextField
                label="feeds.eventGroupStartDate"
                source="eventGroupStartDate"
            />
            <TextField
                label="feeds.eventGroupEndDate"
                source="eventGroupEndDate"
            />
            <TextField
                label="feeds.eventGroupDisciplineName"
                source="eventGroupDisciplineName"
            />
            <TextField
                label="feeds.eventGroupDisciplineHandle"
                source="eventGroupDisciplineHandle"
            />
            <TextField
                label="feeds.eventGroupDivision"
                source="eventGroupDivision"
            />
            <TextField
                label="feeds.eventGroupCountry"
                source="eventGroupCountry"
            />
            <TextField
                label="feeds.eventGroupLocation"
                source="eventGroupLocation"
            />
            <TextField
                label="feeds.eventGroupGoverningBodyHandle"
                source="eventGroupGoverningBodyHandle"
            />
            <TextField
                label="feeds.eventGroupGoverningBody"
                source="eventGroupGoverningBody"
            />
            <TextField
                label="feeds.eventGroupGoverningBodyAbbreviation"
                source="eventGroupGoverningBodyAbbreviation"
            />
            <TextField
                label="feeds.eventGroupFormat"
                source="eventGroupFormat"
            />
            <TextField
                label="feeds.eventGroupFormatAbbreviation"
                source="eventGroupFormatAbbreviation"
            />
            <TextField
                label="feeds.eventGroupSeries"
                source="eventGroupSeries"
            />
            <TextField
                label="feeds.eventGroupParentHandle"
                source="eventGroupParentHandle"
            />
            <TextField
                label="feeds.personName"
                source="personName"
            />
            <TextField
                label="feeds.personNameFirst"
                source="personNameFirst"
            />
            <TextField
                label="feeds.personNameLast"
                source="personNameLast"
            />
            <TextField
                label="feeds.personAttributes"
                source="personAttributes"
            />
            <TextField
                label="feeds.personNationality"
                source="personNationality"
            />
            <TextField
                label="feeds.personGroupDivision"
                source="personGroupDivision"
            />
            <TextField
                label="feeds.personGroupId"
                source="personGroupId"
            />
            <TextField
                label="feeds.personGroupTypeHandle"
                source="personGroupTypeHandle"
            />
            <TextField
                label="feeds.personGroupName"
                source="personGroupName"
            />
            <TextField
                label="feeds.personGroupUrl"
                source="personGroupUrl"
            />
            <TextField
                label="feeds.personGroupDisciplineHandle"
                source="personGroupDisciplineHandle"
            />
            <TextField
                label="feeds.personGroupYear"
                source="personGroupYear"
            />
            <TextField
                label="feeds.personGroupEstablished"
                source="personGroupEstablished"
            />
            <TextField
                label="feeds.excludeArticleTagUrl"
                source="excludeArticleTagUrl"
            />
            <TextField
                label="feeds.excludeArticleProduct"
                source="excludeArticleProduct"
            />
            <TextField
                label="feeds.excludeArticleProductHandle"
                source="excludeArticleProductHandle"
            />
            <TextField
                label="feeds.excludeArticleType"
                source="excludeArticleType"
            />
            <TextField
                label="feeds.excludeArticleTagType"
                source="excludeArticleTagType"
            />
            <TextField
                label="feeds.excludeArticleTag"
                source="excludeArticleTag"
            />
            <TextField
                label="feeds.excludeArticleVerticalHandle"
                source="excludeArticleVerticalHandle"
            />
            <TextField
                label="feeds.excludeArticleCategoryHandle"
                source="excludeArticleCategoryHandle"
            />
            <TextField
                label="feeds.excludeArticleVertical"
                source="excludeArticleVertical"
            />
            <TextField
                label="feeds.excludeArticleAuthorHandle"
                source="excludeArticleAuthorHandle"
            />
            <TextField
                label="feeds.excludeArticleMediaRights"
                source="excludeArticleMediaRights"
            />
            <TextField
                label="feeds.excludeLegacyId"
                source="excludeLegacyId"
            />
            <TextField
                label="feeds.excludeArticleTagHandle"
                source="excludeArticleTagHandle"
            />
            <TextField
                label="feeds.excludeArticleId"
                source="excludeArticleId"
            />
            <DateField
                label="feeds.dateTo"
                source="dateTo"
            />
            <DateField
                label="feeds.dateFrom"
                source="dateFrom"
            />
            <TextField
                label="feeds.facets"
                source="facets"
            />
            <TextField
                label="feeds.searchResponseExcludeFields"
                source="searchResponseExcludeFields"
            />
            <TextField
                label="feeds.searchResponseType"
                source="searchResponseType"
            />
            <TextField
                label="feeds.searchResponseHeader"
                source="searchResponseHeader"
            />
            <TextField
                label="feeds.searchAlgorithm"
                source="searchAlgorithm"
            />
            <TextField
                label="feeds.start"
                source="start"
            />
            <TextField
                label="feeds.rows"
                source="rows"
            />
            <TextField
                label="feeds.page"
                source="page"
            />
            <SelectField
                label="feeds.operator"
                source="operator"
                choices={[
                    { id: 'AND', name: 'AND' },
                    { id: 'OR', name: 'OR' }
                ]}
            />
            <ReferenceArrayField
                label="feeds.companies"
                reference="corporations"
                source="companies"
            >
                <SingleFieldList>
                    <ChipField source="headline" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField
                label="feeds.articleLabel"
                source="articleLabel"
            />
            <TextField
                label="feeds.articleMediaTitle"
                source="articleMediaTitle"
            />
            <TextField
                label="feeds.articleMetaCanonical"
                source="articleMetaCanonical"
            />
            <RichTextField
                label="feeds.articleMetaDescription"
                source="articleMetaDescription"
            />
            <TextField
                label="feeds.articleName"
                source="articleName"
            />
            <TextField
                label="feeds.articleName"
                source="articleName"
            />
            <TextField
                label="feeds.exclude_tags"
                source="exclude_tags"
            />
            <RichTextField
                label="feeds.articleProductText"
                source="articleProductText"
            />
            <TextField
                label="feeds.articleTag"
                source="articleTag"
            >
            </TextField>
            <TextField
                label="feeds.articleTagType"
                source="articleTagType"
            >
            </TextField>
            <TextField
                label="feeds.articleTagTypeTagHandle"
                source="articleTagTypeTagHandle"
            >
            </TextField>
            <TextField
                label="feeds.articleType"
                source="articleType"
            />
            <TextField
                label="feeds.canonicalUrl"
                source="canonicalUrl"
            />
            <TextField
                label="feeds.productCategory"
                source="productCategory"
            />
            <TextField
                label="feeds.productName"
                source="productName"
            />
            <TextField
                label="feeds.productInfoLabels"
                source="productInfoLabels"
            />
            <NumberField
                label="feeds.productRating"
                source="productRating"
            />
            <NumberField
                label="feeds.productInfoValues"
                source="productInfoValues"
            />
            <BooleanField
                label="feeds.publishedState"
                source="publishedState"
            />
            <TextField
                label="feeds.tagType"
                source="tagType"
            />
            <TextField
                label="feeds.content_syndication_control_tag"
                source="content_syndication_control_tag"
            />
            <TextField
                label="feeds.content_syndication_tag_handle"
                source="content_syndication_tag_handle"
            />
            <TextField
                label="feeds.tracking"
                source="tracking"
            />
            <TextField
                label="feeds.custom_query"
                source="custom_query"
            />
            <NumberField
                label="feeds.ttl"
                source="ttl"
            />
            <TextField
                label="feeds.custom_filter_query"
                source="custom_filter_query"
            />
            <TextField
                label="feeds.custom_filter"
                source="custom_filter"
            />
            <RichTextField
                label="feeds.text"
                source="text"
            />
            <RichTextField
                label="feeds.comment"
                source="comment"
            />
            <BooleanField
                label="feeds.exclude_embedded_jwp"
                source="exclude_embedded_jwp"
            />
        </SimpleShowLayout>
    </Show>
);

export default reduxForm({
    form: 'ArrayField'
})(FeedsShow)
